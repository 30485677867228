import { createSelector } from '@reduxjs/toolkit';
import { UniqueIdentifier } from '../../../types';

export interface SiteType {
  id: UniqueIdentifier;
  type: string;
  brandSubPath: boolean;
}

export const selectSiteType = createSelector(
  [(state) => state.siteType.data as SiteType],
  (data) => data,
);
